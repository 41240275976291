<template>
  <v-main>

    <!-- desktop app bar -->
    <v-app-bar v-if="$vuetify.breakpoint.mdAndUp" app flat :class="appBarBorderThemeClass">
      <v-img v-if="$env.APP_MODE === 'ppo'" max-height="56" max-width="154" contain
             class="mb-2 ml-2 mr-2" src="https://www.rubiart.pl/img/logo-transparent.png?v2"
             @click="onMenuItemClick('/')" style="cursor: pointer"/>
      <v-img v-else max-height="56" max-width="56" contain
             class="mb-2 ml-2 mr-2 mt-2" src="/gls-logo.png"
             @click="onMenuItemClick('/')" style="cursor: pointer"/>
      <v-toolbar-title style="font-size: 1.5em">
        {{ $env.APP_MODE === 'ppo' ? 'Rankingi' : 'Gdańska Liga Speedcubingu' }}
      </v-toolbar-title>
      <v-spacer/>
      <v-menu v-for="menuItem in menuItems" v-bind:key="menuItem.title"
              offset-y open-on-hover transition="slide-y-transition">
        <template v-slot:activator="{on, attrs}">
          <v-btn depressed style="text-transform: unset" class="ml-3" v-on="on" v-bind="attrs"
                 @click="onMenuItemClick(menuItem.route, menuItem.url)">
            {{ menuItem.title }}
            <v-icon v-if="menuItem.items.length > 0">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list v-if="menuItem.items.length > 0">
          <v-list-item v-for="listItem in menuItem.items" v-bind:key="listItem.title"
                       link @click="onMenuItemClick(listItem.route, listItem.url)">
            <v-list-item-icon>
              <v-icon>{{ listItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ listItem.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ listItem.subtitle }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- mobile app bar -->
    <v-app-bar v-else app flat :class="appBarBorderThemeClass">
      <v-img max-height="56" max-width="154" contain class="mb-2 ml-1 mr-1"
             src="https://www.rubiart.pl/img/logo-transparent.png?v2"
             @click="onMenuItemClick('/')" style="cursor: pointer"/>
      <v-toolbar-title style="font-size: 1.5em">Rankingi</v-toolbar-title>
      <v-spacer/>
      <v-icon @click="drawer = !drawer">mdi-menu</v-icon>
    </v-app-bar>

    <!-- mobile nav bar -->
    <v-navigation-drawer temporary app v-model="drawer">
      <v-list>
        <v-list-item v-for="item in mobileMenuItems" v-bind:key="item.title"
                     link @click="onMenuItemClick(item.route, item.url)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- router view -->
    <router-view/>

  </v-main>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    drawer: false,
    mobileMenuItems: [{
      title: 'Nadchodzące zawody',
      icon: 'mdi-open-in-new',
      url: 'https://zawody.rubiart.pl/'
    }, {
      title: 'Zawody',
      icon: 'mdi-calendar-outline',
      route: '/competitions'
    }, {
      title: 'Rankingi',
      icon: 'mdi-format-list-numbered',
      route: '/rankings'
    }, {
      title: 'Rekordy',
      icon: 'mdi-trophy-outline',
      route: '/records'
    }, {
      title: 'Zawodnicy',
      icon: 'mdi-account-group-outline',
      route: '/competitors'
    }, {
      title: 'Klasyfikacje',
      icon: 'mdi-clipboard-list-outline',
      route: '/series/GLS2025'
    }]
  }),
  methods: {
    onMenuItemClick(route, url) {
      if (route != null)
        this.$router.push(route);
      else if (url != null)
        window.open(url, '_blank');
    }
  },
  computed: {
    appBarBorderThemeClass() {
      return this.$vuetify.theme.dark ? 'app-bar-border-dark' : 'app-bar-border-light';
    },
    menuItems() {
      return [{
        title: 'Zawody',
        items: [{
          title: 'Nadchodzące zawody',
          subtitle: 'Weź udział w nadchodzących zawodach',
          icon: 'mdi-open-in-new',
          url: 'https://zawody.rubiart.pl/'
        }, {
          title: 'Zawody',
          subtitle: 'Sprawdź wyniki z zakończonych zawodów',
          icon: 'mdi-calendar-outline',
          route: '/competitions'
        }]
      }, {
        title: 'Wyniki',
        items: [{
          title: 'Rankingi',
          subtitle: 'Sprawdź swoje miejsce w ' + (this.$env.APP_MODE === 'ppo' ? 'ogólnopolskim ' : '') + 'rankingu',
          icon: 'mdi-format-list-numbered',
          route: '/rankings'
        }, {
          title: 'Rekordy',
          subtitle: 'Sprawdź aktualne rekordy i rekordzistów',
          icon: 'mdi-trophy',
          route: '/records'
        }, {
          title: 'Zawodnicy',
          subtitle: 'Zobacz wszystkich uczestników',
          icon: 'mdi-account-group',
          route: '/competitors'
        }]
      }, {
        title: 'Klasyfikacje',
        items: [],
        route: '/series/GLS2025' // TODO: remove hardcoded series, create current series endpoint
      }]
    }
  }
};
</script>

<style scoped>
.app-bar-border-light {
  border-bottom: 1px solid #e0e0e0;
}

.app-bar-border-dark {
  border-bottom: 1px solid #2f2f2f;
}
</style>
